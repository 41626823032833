import React from "react";
import ReactWebChat, {
  createDirectLine,
  createStyleSet,
  createStore,
} from "botframework-webchat";
import { useEffect, useMemo, useState } from "react";

const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;

function App() {
  const [directLineToken, setDirectLineToken] = useState<string>("");
  const checkActivity = (activity: any) => {
    if (
        activity.value &&
        activity.value.Action
    ) {
        const btnLabelIdentifier = activity.value.Action;
        const btnList = document.querySelectorAll(`[aria-label="${btnLabelIdentifier}"]`) as NodeListOf<HTMLButtonElement> | null;
        btnList?.forEach(btn => {
          btn.disabled = true;
        });          
    }
};

  const store = useMemo(() => createStore(
      {},
      () => (next:any) => (action:any) => {
          if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
              checkActivity(action.payload.activity);
          }

          return next(action);
      }
  ), []);
  const contextId = new URLSearchParams(window.location.search).get(
    "contextId"
  );

  useEffect(() => {
    if (contextId !== null && AUTH_ENDPOINT !== undefined)
      fetch(AUTH_ENDPOINT.replace("{{CONTEXT_ID}}", contextId))
        .then((res) => res.text())
        .then((token) => {
          setDirectLineToken(token);
        });
  }, [setDirectLineToken, contextId]);

  const directLine = useMemo(
    () =>
      directLineToken !== ""
        ? createDirectLine({ token: directLineToken })
        : null,
    [directLineToken]
  );

  // const DEFAULT_ACCENT = '#000000';
  const styleSet = createStyleSet({
    // Basic
    backgroundColor: '#ecf0f1',

    /*
      accent: DEFAULT_ACCENT, 
      backgroundColor: '#FFFFFF',
      primaryFont: 'Helvetica Neue, Helvetica, Arial, Lucida Grande, Kohinoor Devanagari, sans-serif',
      fontSizeSmall: '80%',
    
      /*
      // bubbleFromBot
      bubbleBackground: '#FFFFFF',
      bubbleBorderRadius: 7.5,
      bubbleBorderStyle: 'none',

      bubbleFromUserBackground: '#dcf8c6',
      bubbleFromUserBorderRadius: 7.5,
      bubbleFromUserBorderStyle: 'none',

      bubbleMinHeight: 19,
      bubbleMinWidth: 200,

      // Suggested actions
      suggestedActionBackground: '#FFFFFF',
      suggestedActionBorderRadius: 20,
      suggestedActionBorderStyle: 'solid',
      suggestedActionBorderColor: 'E6E6E6',
      suggestedActionBorderWidth: 1,
      suggestedActionHeight: 40,
      suggestedActionLayout: 'caroussel',
      suggestedActionTextColor: '#00a5f4',
      suggestedActionsCarouselFlipperCursor: 'pointer',

      transcriptTerminatorBorderRadius: 20,
      */
  });

  const styleOptions = {
    // Avatar
    botAvatarInitials: 'Coke',
    hideUploadButton: true,
    /*
    bubbleBorderStyle: "solid",
    bubbleBorderWidth: 1,
    
    bubbleNubOffset: 'top',
    bubbleNubSize: .9,
    bubbleFromUserNubOffset: 'top',
    bubbleFromUserNubSize: .9,
    */
  };

  return (
    <div className="webChat">
      {directLine && (
        <ReactWebChat
          directLine={directLine}
          styleSet={styleSet}
          styleOptions={styleOptions}
          store={store}
        />
      )}
    </div>
  );
}

export default App;
