import React from "react";
import logo from ".././Coca-logo.png";

const REPO_URL = process.env.REACT_APP_REPO_URL;

function App() {

  const providerName = new URLSearchParams(window.location.search).get("pr");
  const providerRegion = new URLSearchParams(window.location.search).get("rg");

  let url = '';
  
  if (REPO_URL !== undefined) {
    url = REPO_URL.replace("{{providerName}}", providerName ? providerName : "ko")
                  .replace("{{providerRegion}}", providerRegion ? providerRegion : "mx");
  }

  return (
    <div className="header-container">
      <div>
        <div className="image-box">
          <img src={url} alt="Coca-Cola" className="icon" />
        </div>
        <div className="description-box">
          <img src={logo} alt="Coca-Cola" className="icon-des" />
        </div>
      </div>
    </div>
  );
}

export default App;
